<template>
    <div>
        <b-card>
            <XcTable
                ref="shipmentTable"
                endpoint="shipment-plans"
                :columns="tableColumns"
                :buttons="tableButtons"
                :records-per-page="100"
                :search-enabled="true"
            />
        </b-card>

        <modal-confirm-vuexy
            modal-id="deleteShipmentPlan"
            reference="deleteShipmentPlan"
            text="Are you sure you want to delete this shipment plan?"
            title="Delete shipment plan"
            :ok-function="deleteShipmentPlan"
        />

    </div>
</template>

<script>
import XcTable from '@/components/Misc/XcTable.vue'
import XcTableHelper from '@/components/Misc/XcTableHelper'
import Axios from 'axios'
import {
    BCard,
} from 'bootstrap-vue'
import ModalConfirmVuexy from '@/components/Modals/ModalConfirmVuexy'
import Integrations from '@/services/Integrations'

export default {
    name: 'shipments.shipment-plans',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        XcTable,
        ModalConfirmVuexy,
        BCard,
    },
    data() {
        return {
            tableButtons: [],
            tableColumns: [
                {
                    type: XcTableHelper.TYPE_LINK,
                    title: 'ID',
                    name: 'id',
                    field: 'id',
                    label: 'ID',
                    sortByDefault: XcTableHelper.ORDER_BY_DESC,
                    callback: this.viewShipmentPlan
                },
                {
                    type: XcTableHelper.TYPE_LINK,
                    name: 'name',
                    title: 'Name',
                    field: 'name',
                    label: 'Name',
                    callback: this.viewShipmentPlan
                },
                {
                    type: XcTableHelper.TYPE_BADGE,
                    name: 'status',
                    title: 'Status',
                    field: 'status',
                    label: 'Status',
                },
                {
                    type: XcTableHelper.TYPE_TEXT,
                    name: 'created_at',
                    title: 'Created at',
                    field: 'created_at',
                    label: 'Created at',
                },
                // {
                //     type: XcTableHelper.TYPE_BUTTONS,
                //     disableSort: true,
                //     title: '',
                //     field: 'action',
                //     label: 'Action',
                //     buttons: [
                //         {
                //             class: 'mr-50',
                //             icon: 'TrashIcon',
                //             label: 'Delete',
                //             callback: this.deleteShipmentPlanConfirm,
                //         },
                //     ],
                // },
            ],
            plantToDelete: 0,
        }
    },
    methods: {
        deleteShipmentPlanConfirm(id) {
            this.plantToDelete = id
            this.$bvModal.show('deleteShipmentPlan')
        },
        async deleteShipmentPlan() {
            await Axios({
                url: `${process.env.VUE_APP_API_HTTP_ROOT}shipment-plans/delete`,
                data: {id: this.plantToDelete},
                method: 'DELETE'
            })
            this.$refs.shipmentTable.loadData()
        },
        viewShipmentPlan(id) {
            this.$router.push({name: 'shipments.viewAmazonShipmentPlan', params: {id}})
        },
    },
}
</script>
